import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SidebarContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-basis: 77px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 0 10px 10px 0;
    z-index: 9995;

    background-color: ${theme.validateMode({ light: '#F8FDFF' })};
    border-right: ${theme.validateMode({
      light: '1px solid #CAC4D0',
      dark: '1px solid #49454F',
    })};
    ${theme.validateMode({
      light: 'box-shadow: 4px 0px 8px 0px #65778833',
      dark: theme.elevationCA[3],
    })};

    &.has-children {
      border-radius: 0;
    }

    .sidebar-logo-container {
      display: flex;
      justify-content: center;
      height: 107px;
      padding-top: 16px;

      &.is-mobile {
        justify-content: space-between;
        height: auto;
        align-items: center;
        padding-bottom: 0.75rem;
        padding-top: 0;
        margin-inline: -10px;
        padding-inline: 10px;
        border-bottom: 1px solid #cac4d0;
      }

      img {
        height: 44px;
        width: 44px;
      }
    }

    .sidebar-options-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.hidden {
        display: none;
      }

      .option {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin-bottom: 22px;
        cursor: pointer;
        color: ${theme.validateMode({
          light: theme.colorsCA.neutral[40],
          dark: theme.colorsCA.neutral[95],
        })};

        &:hover {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};

          .tooltip {
            visibility: visible;
          }
        }

        &__active {
          box-shadow: 0px 4px 4px 0px #00000040 inset;
          color: ${theme.colorsCA.neutral[95]};
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[70],
            dark: theme.colorsCA.darkBlue[60],
          })};

          &:hover {
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[70],
              dark: theme.colorsCA.darkBlue[60],
            })};
          }
        }

        &__children {
          &:hover {
            cursor: default;
          }
        }

        &__active-children {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};
        }
      }

      .icon {
        height: 20px;
      }

      .tooltip {
        visibility: hidden;
        position: absolute;
        left: 89px;
        padding: 11px 16px;
        border-radius: 10px;
        border: 1px solid
          ${theme.validateMode({
            light: '#CAC4D0',
            dark: '#49454F',
          })};
        background: ${theme.validateMode({
          light: 'rgba(255, 255, 255, 0.5)',
          dark: 'rgba(74, 70, 81, 0.50)',
        })};
        box-shadow: 0px 4px 20px 0px
          ${theme.validateMode({
            light: 'rgba(101, 119, 136, 0.2)',
            dark: 'rgba(0, 0, 0, 0.20)',
          })};
        backdrop-filter: blur(21px);
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        font-size: 16px;
        font-weight: 600;
        z-index: 9999;
      }
    }

    &.sidebar-secondary {
      background: ${theme.validateMode({
        light: '#FCFFF4',
        dark: 'linear-gradient(0deg, rgba(217, 255, 188, 0.12) 0%, rgba(217, 255, 188, 0.12) 100%), #1C1B1F',
      })};

      .sidebar-options-container {
        .option {
          &__active {
            background-color: #83aa32;
          }
        }
      }

      .menu-sidebar {
        background: ${theme.validateMode({
          light: '#FCFFF4',
          dark: 'linear-gradient(0deg, rgba(217, 255, 188, 0.12) 0%, rgba(217, 255, 188, 0.12) 100%), #1C1B1F',
        })};

        .menu-order-container {
          .menu-order-icon {
            &__active {
              background-color: #83aa32;
            }
          }
        }

        .menu-options-container {
          .menu-option {
            &__active {
              background-color: #83aa32;
            }
          }
        }
      }
    }
    &.is-mobile {
      flex-basis: unset;
      height: 100%;
      padding: 0.625rem;
      margin-right: 0;
      width: 267px;
      border-radius: 0 10px 10px 0;

      .menu-icon {
        cursor: pointer;
      }

      .sidebar-options-container {
        align-items: start;
        margin-top: 1rem;
        gap: 0.75rem;
        .option {
          width: 100%;
          height: 40px;
          border-radius: 100px;
          margin-bottom: 0;
          align-items: center;
          padding-right: 10px;
          color: ${theme.validateMode({
            light: theme.colorsCA.neutral[40],
            dark: theme.colorsCA.neutral[95],
          })};
          &:hover {
            background-color: var(
              --surfaces-light-surface-1,
              linear-gradient(0deg, rgba(0, 43, 84, 0.05) 0%, rgba(0, 43, 84, 0.05) 100%),
              #fffbfe
            );
          }
          &:active {
            background-color: var(--color-blue-md-ref-palette-blue-95, #e1f4fc);
          }

          &:hover {
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[95],
              dark: theme.colorsCA.neutral[30],
            })};
          }

          &__active {
            box-shadow: none;
            background-color: ${theme.validateMode({
              light: 'var(--color-blue-md-ref-palette-blue-95, #e1f4fc)',
              dark: theme.colorsCA.darkBlue[60],
            })};
            color: ${theme.validateMode({
              light: theme.colorsCA.neutral[40],
              dark: theme.colorsCA.neutral[95],
            })};

            &:hover {
              background-color: ${theme.validateMode({
                light: 'var(--color-blue-md-ref-palette-blue-95, #e1f4fc)',
                dark: theme.colorsCA.darkBlue[60],
              })};
            }
          }

          .label {
            color: ${theme.validateMode({
              light: theme.colorsCA.neutral[40],
              dark: theme.colorsCA.neutral[95],
            })};
            font-family: 'Work Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }
    }
  `}
`;

export const MobileSubMenuContainer = styled.div`
  ${({ theme }) => css`
    .menu-sidebar {
      max-height: 92vh;
      width: 100%;
      padding: 16px 0 0;
      border: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      z-index: 9999;
      left: 0;
      border-radius: 0;

      &.hidden {
        display: none;
      }

      .menu-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px 0 0;

        .MuiSvgIcon-root {
          width: 24px;
          height: 24px;
        }

        span {
          margin-left: 10px;
          font-family: Work Sans;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          text-align: left;
        }
      }

      .menu-order-container {
        display: flex;
        min-height: 40px;
        width: 120px;
        border-width: 1px;
        border-style: solid;
        border-radius: 100px;
        margin: 20px 18px 0;

        .menu-order-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          border-radius: 100px 0 0 100px;
          cursor: pointer;

          .MuiSvgIcon-root {
            font-size: 18px;
          }

          &:hover {
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[95],
              dark: theme.colorsCA.neutral[30],
            })};
          }

          &:last-child {
            border-left-style: solid;
            border-left-width: 1px;
            border-radius: 0 100px 100px 0;
          }

          &__active {
            color: ${theme.colorsCA.neutral[95]};
            pointer-events: none;
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[70],
              dark: theme.colorsCA.darkBlue[60],
            })};
          }
        }
      }

      .menu-search-container {
        display: flex;
        margin: 20px 18px 0;
      }

      .menu-options-container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin: 8px 0;
        padding: 0;
        gap: 0.75rem;
      }

      .menu-option {
        padding: 14px;
        border-radius: 100px;
        cursor: pointer;
        display: flex;
        height: 40px;
        display: flex;
        align-items: center;
        color: ${theme.validateMode({
          light: theme.colorsCA.neutral[40],
          dark: theme.colorsCA.neutral[95],
        })};

        &:hover {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};
        }

        &__active {
          color: ${theme.colorsCA.neutral[95]};
          pointer-events: none;
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[70],
            dark: theme.colorsCA.darkBlue[60],
          })};
          &.is-mobile {
            box-shadow: unset !important;
          }
        }

        &__children {
          &:hover {
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[95],
              dark: theme.colorsCA.neutral[30],
            })};
          }
        }

        span {
          font-size: 14px;
          font-weight: 500;
        }

        &.is-mobile {
          span {
            font-family: Work Sans;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
          }
        }
      }
    }
  `}
`;

export const StyledSubMenuContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-left: -217px;
    transition: all ease 0.3s;
    position: absolute;
    width: 217px;
    flex-direction: column;
    left: 77px;
    height: 100%;
    z-index: 9990;
    border-radius: 0 10px 10px 0;
    background-color: ${theme.validateMode({ light: '#F8FDFF' })};
    border-right: ${theme.validateMode({
      light: '1px solid #CAC4D0',
      dark: '1px solid #49454F',
    })};
    ${theme.validateMode({
      light: 'box-shadow: 4px 0px 8px 0px #65778833',
      dark: theme.elevationCA[3],
    })};

    &.activated-sub-menu {
      margin-left: 0;
    }

    .menu-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 18px 18px 0;

      .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
      }

      span {
        font-size: 20px;
        font-weight: 700;
        margin-left: 10px;
      }
    }

    .menu-order-container {
      display: flex;
      min-height: 40px;
      width: 120px;
      border-width: 1px;
      border-style: solid;
      border-radius: 100px;
      margin: 20px 18px 0;

      .menu-order-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        border-radius: 100px 0 0 100px;
        cursor: pointer;

        .MuiSvgIcon-root {
          font-size: 18px;
        }

        &:hover {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};
        }

        &:last-child {
          border-left-style: solid;
          border-left-width: 1px;
          border-radius: 0 100px 100px 0;
        }

        &__active {
          box-shadow: 0px 4px 4px 0px #00000040 inset;
          color: ${theme.colorsCA.neutral[95]};
          pointer-events: none;
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[70],
            dark: theme.colorsCA.darkBlue[60],
          })};
        }
      }
    }

    .menu-search-container {
      display: flex;
      margin: 20px 18px 0;
    }

    .menu-options-container {
      display: flex;
      flex-direction: column;
      overflow: auto;
      margin: 18px 0;
      padding: 0 18px;
    }

    .menu-option {
      padding: 14px;
      border-radius: 10px;
      cursor: pointer;
      color: ${theme.validateMode({
        light: theme.colorsCA.neutral[40],
        dark: theme.colorsCA.neutral[95],
      })};

      &:hover {
        background-color: ${theme.validateMode({
          light: theme.colorsCA.darkBlue[95],
          dark: theme.colorsCA.neutral[30],
        })};
      }

      &__active {
        box-shadow: 0px 4px 4px 0px #00000040 inset;
        color: ${theme.colorsCA.neutral[95]};
        pointer-events: none;
        background-color: ${theme.validateMode({
          light: theme.colorsCA.darkBlue[70],
          dark: theme.colorsCA.darkBlue[60],
        })};
      }

      &__children {
        &:hover {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};
        }
      }

      span {
        font-size: 16px;
        font-weight: 600;
      }
    }
  `}
`;
